import dynamic from 'next/dynamic'

import {
	ELanguage,
	HogProvider,
	TAuthUser,
	TNestedGenericId,
	Toast,
	Utils,
	colors
} from '@microfrontends/react-components'
import AppContext from '../../context/AppContext'
import Head from '../head'
import IdentifyUser from '../IdentifyUser'
import TopBanner from '../TopBanner'
import {
	EMode,
	EPages,
	Footer,
	Header,
	TUser,
	initialDataProvider
} from '@microfrontends/app-shell-v2'
import PopupBanner from '../Banners/PopupBanner'
import { PropsWithChildren, memo, useEffect, useState } from 'react'
import { isMobile as isMb } from '../../helpers'
import { getDetectLocationId } from '../../helpers/helper'
import { EVENT_DETECT_LOCAITON, EVENT_UPDATE_USER } from '../constants'
import { TDeviceViewPort, TSSRResultData } from '../../ssr/home'
import * as Sentry from '@sentry/node'
import { translation } from '../../static/locales'
import { decrypt } from '../../services/auth/cryptor'

const Notification = dynamic(() => import('../Notification'), { ssr: false })
const GlobalScripts = dynamic(() => import('../GlobalScripts'), { ssr: false })

interface IProps {
	langCode: ELanguage
	user: TAuthUser
	userIP: string
	resultAll?: Partial<TSSRResultData>
	viewport: TDeviceViewPort
}

const headImages =
	`https://images.vietnamworks.com/images/vnw_empower_growth_logo.png?version=` +
	process.env.VNW_BUILD_ID

const APP_URL = process.env.APP_BASE_URL ?? ''

const GTM_ID = process.env.GOOGLE_TAG_MANAGER_ID

function MainLayout({
	user: userProp,
	userIP,
	langCode,
	children,
	viewport,
	resultAll
}: PropsWithChildren<IProps>) {
	const isMobile = isMb()
	const [locations, setLocations] = useState<TNestedGenericId[]>([])

	const t = Utils.createTranslator({
		translations: translation,
		currentLanguage: langCode
	})

	const [user, setUser] = useState<TAuthUser>(userProp)

	const getLocationsFromLocalStorage = () => {
		const locations: TNestedGenericId[] = []
		const params: string | null =
			typeof window !== 'undefined' ? localStorage.getItem('params') : ''
		const localParams =
			params !== '' && params !== null ? JSON.parse(params) : {}
		// Check 'locations' params exist
		if (localParams['locations'] !== undefined) {
			return localParams['locations']
		}
		// 'locations' is not exist then check 'locationId' (Old location variable)
		if (localParams['locationId'] !== undefined) {
			return localParams['locationId'].map((item: number) => {
				// map location array number to locations with district
				return {
					parentId: item,
					childrenIds: [-1]
				}
			})
		}

		// Return empty if there is no variable exists
		return locations
	}

	async function initSelectData() {
		const dataKey: any[] = ['industry', 'jobFunction']

		await initialDataProvider.initSelectData({
			selectDataKey: dataKey,
			mode: process.env.APP_SHELL_MODE as EMode,
			langCode: langCode
		})
	}

	useEffect(() => {
		const localStoreLocations = getLocationsFromLocalStorage()
		if (localStoreLocations) {
			setLocations(localStoreLocations)
		}

		function trackPWAInstallation() {
			if (typeof window !== 'undefined') {
				window.addEventListener('appinstalled', function () {
					Sentry.captureMessage(
						`[PWA] VNW PWA was INSTALLED`,
						Sentry.Severity.Info
					)
				})
			}
		}

		trackPWAInstallation()
		initSelectData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const getDetectLocationInCookie = () => {
			const detectedCityIdRaw = getDetectLocationId()
			if (detectedCityIdRaw === undefined) {
				return []
			}

			const detectedCityIdListRaw = detectedCityIdRaw.split(',')

			let detectedLocation: TNestedGenericId[] = []
			if (!!resultAll?.metaCities) {
				for (let i = 0; i < detectedCityIdListRaw.length; i++) {
					if (
						resultAll?.metaCities.idBased[
							detectedCityIdListRaw[i]
						] === undefined
					) {
						continue
					}

					detectedLocation.push({
						parentId: Number(detectedCityIdListRaw[i]),
						childrenIds: [-1]
					})
				}
			}
			return detectedLocation
		}

		const onDetectedLocation = () => {
			if (locations && locations.length > 0) return
			const detectedLocation = getDetectLocationInCookie()
			setLocations(detectedLocation)
		}

		const updateUserState = (e: Event) => {
			if (!e?.detail?.user) {
				return
			}

			const token = e?.detail?.user?.accessToken
			setUser({
				...(e.detail.user as TAuthUser),
				accessToken: decrypt(token)
			})
		}

		window.addEventListener(EVENT_DETECT_LOCAITON, onDetectedLocation)
		window.addEventListener(EVENT_UPDATE_USER, updateUserState)

		return () => {
			window.removeEventListener(
				EVENT_DETECT_LOCAITON,
				onDetectedLocation
			)
			window.removeEventListener(EVENT_UPDATE_USER, updateUserState)
		}
	}, [locations, resultAll?.metaCities])

	return (
		<>
			<Head
				keywords={t('Job Search, Recruitment')}
				title={t(
					'VietnamWorks - Recruitment, employment, salary lookup & career advice platform'
				)}
				description={t(
					'Search for recruitment information - jobs - look up salary & interview experience at the website of the largest recruitment platform in Vietnam. Update new work every day. Find out now at VietnamWorks!'
				)}
				ogImage={headImages}
				url={APP_URL}
			/>
			<AppContext.Provider
				value={{
					// Bc SW is State of parent component
					// It will make all component re-render
					// We trace code and see SW var is unused. So we assign this === null
					serviceWorker: null,
					user: user,
					userIp: userIP,
					t: t,
					langCode: langCode,
					locations: locations
				}}
			>
				<HogProvider>
					<IdentifyUser user={user} />
					<TopBanner />
					<Header
						mode={process.env.APP_SHELL_MODE as EMode}
						langCode={langCode}
						user={user as TUser} //conflic type, will refactor later
						isHomePage={true}
						pageName={EPages.HOME_PAGE}
						responsive={{
							type: 'home-page',
							cornerColor: colors.primary[20]
						}}
					/>

					<noscript>
						<iframe
							src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
							height='0'
							width='0'
							style={{ display: 'none', visibility: 'hidden' }}
						/>
					</noscript>
					{children}
					<div id='overlay' />
					<Notification />

					<Footer
						mode={process.env.APP_SHELL_MODE as EMode}
						langCode={langCode}
						seoKeywordData={resultAll?.seoKeywords}
						user={user as TUser}
						bottomSEOContent={{
							html: ``,
							ssr: false
						}}
					/>

					{!isMobile ? (
						<PopupBanner
							langCode={langCode}
							primaryBtnText={t('View now')}
						/>
					) : null}
					<Toast />
				</HogProvider>
			</AppContext.Provider>
			<GlobalScripts
				user={user as TUser}
				metaCities={resultAll?.metaCities}
				langCode={langCode}
				viewport={viewport}
			/>
		</>
	)
}

export default memo(MainLayout)
