import React, { useContext, useState } from 'react'
import {
	Container,
	ImageWrapper,
	Wrapper,
	CloseBtn,
	Link,
	IconWrapper
} from './styles'
import ShieldIcon from './ShieldIcon'
import { Images } from '@microfrontends/react-components'
import AppContext from '../../context/AppContext'
import Image from 'next/legacy/image'
import { BLUR_DEFAULT_IMAGE } from '../constants'

const REDIRECT_LINK = 'https://www.vietnamworks.com/hrinsider/scam-job.html'
const BACKGROUND_IMAGE =
	process.env.VNW_BASE_URL +
	'/assets-page-container/images/topbanner-decoration.png'
export default function TopBanner() {
	const { t } = useContext(AppContext)
	const [showTopBanner, setShowTopBanner] = useState(true)

	return showTopBanner ? (
		<Container>
			<ImageWrapper>
				<Image
					src={BACKGROUND_IMAGE}
					placeholder='blur'
					blurDataURL={BLUR_DEFAULT_IMAGE}
					width={1920}
					height={48}
					priority
					fetchPriority='high'
					alt='Top banner background'
				/>
			</ImageWrapper>
			<Wrapper>
				<IconWrapper>
					<ShieldIcon />
				</IconWrapper>
				{t('Protect yourself against VietnamWorks impersonation scams')}
			</Wrapper>
			<CloseBtn
				onClick={() => setShowTopBanner(false)}
				aria-label='close'
			>
				<Images.CloseIcon fill='#fff' />
			</CloseBtn>
			<Link href={REDIRECT_LINK} target='_blank'>
				{t('View more')}
			</Link>
		</Container>
	) : null
}
